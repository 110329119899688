<template>
  <div class="d-flex flex-column justify-content-center align-items-center w-full text-center" style="height: 100vh;">
    <b>Erreur</b>
    <h1 class="font-weight-bolder">404</h1>
    <span>Cette page n'existe pas...</span>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log('current route:', this.$route);
  }
}
</script>