import {deviceMobileCheck} from "@/services/deviceService";
import NotFoundView from "@/desktopViews/NotFoundView.vue";

const isMobile = deviceMobileCheck();


export const websiteRouter = [
  {
    path: '/order/checkout/:orderToken/:shareToken?',
    name: 'checkout',
    meta: {
      title: 'Votre commande'
    },
    component: function (){
      if(isMobile){
        return import('@/mobileViews/Checkout');
      }
      else{
        return import('@/desktopViews/Checkout');
      }
    }
  },
  {
    path: '/order/ano_account',
    name: 'ano_account',
    meta: {
      title: 'Enregistrer votre compte'
    },
    component: function (){
      if(isMobile){
        return import('@/mobileViews/Anonymous/AnonymousMigration');
      }
    }
  },
  {
    path: '/order/ano_account/success',
    name: 'ano_account_success',
    meta: {
      title: 'Félicitations !'
    },
    component: function (){
      if(isMobile){
        return import('@/mobileViews/Anonymous/AnonymousSuccess');
      }
      else{
        //return import('@/desktopViews/Checkout');
      }
    }
  },
  {
    path: '/order/',
    name: 'merchant',
    component: function (){
      if(isMobile){
        return import('@/mobileViews/Merchant');
      }
      else{
        return import('@/desktopViews/Merchant');
      }
    }
  },
  {
    path: '/order/summary',
    name: 'summary',
    meta: {
      title: 'Delicity : Connexion'
    },
    component: function (){
      if(isMobile){
        return false;
      }
      else{
        return import('@/desktopViews/Summary');
      }
    }
  },
  {
    path: '/',
    redirect: '/order/'
  },
  {
    path: '*',
    component: NotFoundView
  }
]