<template>
  <vue-final-modal name="payment-method-selector" v-bind="$attrs" v-slot="{close}" classes="vfm-container" content-class="vfm-content vfm-content-small" v-on="$listeners">
    <modal-header @close="close" class="d-none d-md-flex">Sélectionnez un moyen de paiement</modal-header>
    <modal-header @close="close" class="d-flex d-md-none">Moyen de paiement</modal-header>

    <div class="vfm-body vfm-scroll">
      <ui-alert class="mb-0" type="error" @dismiss="error=null" v-if="error">
        {{error}}
      </ui-alert>


      <div class="pm-list">
        <div class="pm-item"  :class="{'pm-item-active': pmId==='onsite'}" v-if="merchant.options.onsitePaymentEnabled && (cart.type === 'pickup' || cart.type === 'on_site_counter')" @click="pmId='onsite'">
          <div class="item__action">
            <ui-radio true-value="onsite" value="onsite" v-model="pmId" name="pmid"/>
          </div>
          <div class="item__content">
            <strong>Paiement sur place</strong>
          </div>
          <div class="item__logo">
            <img :src="'/img/card/money.svg'" alt="">
          </div>
        </div>


        <div class="pm-item"  :class="{'pm-item-active': pmId==='add_card'}" v-if="user.paymentMethods.length === 0" @click="addNew">
          <div class="item__action">
            <ui-radio true-value="add_card" value="add_card" v-model="pmId" name="pmid"/>
          </div>
          <div class="item__content">
            <strong>Paiement par carte bancaire</strong>
          </div>
          <div class="item__logo">
            <img :src="'/img/card/card.svg'" alt="">
          </div>
        </div>


        <div class="pm-item pm-item-apple"  :class="{'pm-item-active': pmId==='apple_pay'}" v-if="hasAppleWallet" @click="openWallet('apple_pay')">
          <div class="item__action">
            <ui-radio true-value="apple_pay" value="apple_pay" v-model="pmId" name="pmid" color="white"/>
          </div>
          <div class="item__content">
            <strong>Apple Pay</strong>
          </div>
          <div class="item__logo">
            <img src="https://delicity.b-cdn.net/front/img/card/apple_pay.svg" alt="">
          </div>
        </div>

        <div class="pm-item pm-item-google"  :class="{'pm-item-active': pmId==='google_pay'}" v-if="hasGoogleWallet" @click="openWallet('google_pay')">
          <div class="item__action">
            <ui-radio true-value="google_pay" value="google_pay" v-model="pmId" name="pmid"/>
          </div>
          <div class="item__content">
            <strong>Google Pay</strong>
          </div>
          <div class="item__logo">
            <img src="https://delicity.b-cdn.net/front/img/card/google_pay.svg" alt="">
          </div>
        </div>


        <div class="pm-item" :class="{'pm-item-active': pmId===method.pmId}" v-for="method in user.paymentMethods" @click="pmId=method.pmId">
          <div class="item__action" v-if="showRadio">
            <ui-radio :true-value="method.pmId" :value="method.pmId" v-model="pmId" name="pmid"/>
          </div>
          <div class="item__content">
            <strong class="d-none d-md-block text-capitalize">Carte {{method.brand}}</strong>
            <strong class="d-block d-md-none text-truncate text-capitalize" style="width: 70px;">{{method.brand}}</strong>

            <span class="d-none md:d-block">•• {{method.last4}}</span>
            <span class="d-block md:d-none">{{method.last4}}</span>
            <span>{{method.expireDate}}</span>
          </div>
          <div class="item__logo">
            <img :src="'/img/card/'+method.brand+'.svg'" alt="">
          </div>
        </div>


        <button class="pm-add" @click="addNew" v-if="user.paymentMethods.length > 0">
          <span>Payez avec une autre carte bancaire</span>
          <i class="material-icons">east</i>
        </button>
      </div>
    </div>

    <button class="vfm-action button-shining" :disabled="isLoading" @click="startPayment">
      <span v-if="!isLoading && pmId === 'onsite'">Payer sur place</span>
      <span v-else-if="!isLoading && user.paymentMethods.length === 1 && currentCard">Payer {{cart.values.total.price}}</span>
      <span v-else-if="!isLoading">Payer {{cart.values.total.price}}</span>
      <loader v-else-if="isLoading"></loader>
    </button>
  </vue-final-modal>
</template>



<script>
import {mapActions, mapGetters} from "vuex";
import {$vfm} from "vue-final-modal";
import AddPaymentMethod from "@/components/Modals/Payment/AddPaymentMethod";
import GooglePayButton from "@/components/Wallet/GooglePayButton";
import ApplePayButton from "@/components/Wallet/ApplePayButton";
import WalletModal from "@/components/Modals/Payment/WalletModal";

export default {
  data(){
    return {
      pmId: '',
      isLoading: false,
      error: null,
    }
  },
  components: {GooglePayButton, ApplePayButton},
  methods: {
    ...mapActions(['checkoutPaymentMethodAction', 'getUser', 'checkoutOnsitePayment', 'setPaymentCardAction', 'checkoutCart']),
    addNew(){
      if(this.pmId === 'onsite'){
        this.pmId = '';
      }
      $vfm.show({component: AddPaymentMethod});
      window.history.pushState({}, "",location.pathname + '#addpayment');
    },

    openWallet(wallet){
    //  this.pmId = wallet;
      console.log('Open wallet modal')
      $vfm.show({component: WalletModal, bind: {type:wallet}});
      window.history.pushState({}, "",location.pathname + '#wallet');
    },
    startPayment(){
      if(this.pmId === null && this.showRadio === true){
        this.error = 'Merci de selectionner un moyen de paiement';
        return;
      }

      this.isLoading = true;
      if(this.pmId === 'onsite'){
        this.checkoutOnsitePayment()
            .catch((e) => {
              this.pmId = null;
              this.error = this.$t('errors.'+e);
              this.isLoading = false;
            });
      }
      else if(this.pmId){
        this.checkoutPaymentMethodAction({paymentMethodToken: this.pmId, checkoutOrderToken: this.cart.token, orderId: this.cart.id})
            .catch(async (e) => {
              if(this.$te('errors.'+e)){
                this.error = this.$t('errors.'+e);
              }
              else{
                this.error = this.$t('errors.error_please_try_again');
              }
              if(e === 'card_not_supported'){
                await this.getUser();
                if(this.user.paymentMethods.length > 0){
                  this.pmId = this.user.paymentMethods[0].pmId;
                }
              }
              this.isLoading = false;
            });
      }
      else{
        this.error = 'Merci de selectionner un moyen de paiement';
        this.isLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'cart', 'merchant', 'hasGoogleWallet', 'hasAppleWallet']),
    showRadio(){
      let count = 0;
      if(this.merchant.options.onsitePaymentEnabled && (this.cart.type === 'pickup' || this.cart.type === 'on_site_counter')) count++;
      count += this.user.paymentMethods.length;
      if(this.hasGoogleWallet) count++;
      if(this.hasAppleWallet) count++;
      return count > 1;
    },
    currentCard(){
      return this.user.paymentMethods.find(method => method.pmId === this.pmId);
    }
  },
  mounted() {
    if(this.user.paymentMethods.length === 1){
      this.pmId = this.user.paymentMethods[0].pmId;
    }

    this.$root.$on('3DS-authentication-failed', () => {
      this.isLoading = false;
      this.error = 'Erreur lors de l\'authentification 3DS de votre banque...';
    })
  }
}
</script>




<style lang="scss" scoped>
.pm-list {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 20px;
  padding-bottom: 20px;
  .pm-item
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #f3f3f3;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    user-select: none;

    .item__logo
    {
      width: 40px;
      img{
        width: 40px;
      }
    }
    .item__content
    {
      flex: 1;
      padding: 0 15px;
      display: flex;
      align-items: center;
      gap: 15px;
      span
      {
        color: #5d5d5d;
        font-size: 0.9em;
      }
    }
    .item__action
    {
      width: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      pointer-events: none;
    }
  }
  .pm-item-active
  {
    box-shadow: 0 0 0 2px rgba(#00ADEB,0.5);
    background-color: rgba(#00ADEB,0.08);
  }
  .pm-item-apple
  {
    background-color: #000;
    .item__content
    {
      color: #fff;
    }
  }
  .pm-item-apple.pm-item-active{
    box-shadow: none;
    background-color: #000;
  }

  .pm-add
  {
    border: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    line-height: 1;
    padding: 15px 0;
    span
    {
      font-weight: 500;
    }
    i
    {
      font-size: 1.1em;
    }
  }
}

@media (max-width: 576px) {
  .pm-item
  {
    .item__content
    {
      padding: 0 10px !important;
      gap: 3px;
      font-size: 0.9em;
    }
    .item__logo
    {
      width: 30px !important;
      img{
        width: 30px !important;
      }
    }
  }
}
</style>
