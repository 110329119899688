import {deviceMobileCheck} from "@/services/deviceService";
import OrderComplete from "@/components/OrderComplete.vue";
import OrderFail from "@/components/OrderFail.vue";

const isMobile = deviceMobileCheck();

export const platformRouter = [
    {
        path: '/',
        name: 'catalog',
        meta: {
            title: 'Delicity : Nos restaurants'
        },
        component: function (){
            if(isMobile){
                return import('@/mobileViews/Home');
            }
            else{
                return import('@/desktopViews/Home');
            }
        }
    },
    {
        path: '/nice',
        component: function (){
            location.href = '/?lat=43.70094500049188&lng=7.269939983920888&addressName=Nice';
        },
    },
    {
        path: '/pau',
        component: function (){
            location.href = '/?lat=43.303019&lng=-0.370842&addressName=Pau';
        }
    },
    {
        path: '/nantes',
        component: function (){
            location.href = '/?lat=47.218371&lng=-1.553621&addressName=Nantes';
        },
    },
    {
        path: '/bordeaux',
        component: function (){
            location.href = '/?lat=44.837789&lng=-0.57918&addressName=Bordeaux';
        }
    },
    {
        path: '/lyon',
        component: function (){
            location.href = '/?lat=45.764043&lng=4.835659&addressName=Lyon';
        }
    },
    {
        path: '/order_complete',
        name: 'order_complete',
        meta: {
            title: 'Commande validée'
        },
        component: OrderComplete,
    },
    {
        path: '/order_fail',
        name: 'order_fail',
        meta: {
            title: 'Erreur de paiement'
        },
        component: OrderFail,
    },
    {
        path: '/categories',
        name: 'categories',
        meta: {
            title: 'Categories'
        },
        component: function (){
            if(isMobile){
                return import('@/mobileViews/Categories');
            }
            else{
                return false;
            }
        }
    },
    {
        path: '/:merchantSlug/summary',
        name: 'summary',
        meta: {
            title: 'Delicity : Connexion'
        },
        component: function (){
            if(isMobile){
                return false;
            }
            else{
                return import('@/desktopViews/Summary');
            }
        }
    },
    {
        path: '/:merchantSlug/checkout/:orderToken/:shareToken?',
        name: 'checkout',
        meta: {
            title: 'Votre commande'
        },
        component: function (){
            if(isMobile){
                return import('@/mobileViews/Checkout');
            }
            else{
                return import('@/desktopViews/Checkout');
            }
        }
    },
    {
        path: '/ano_account',
        name: 'ano_account',
        meta: {
            title: 'Enregistrer votre compte'
        },
        component: function (){
            if(isMobile){
                return import('@/mobileViews/Anonymous/AnonymousMigration');
            }
            else{
                //return import('@/desktopViews/Checkout');
            }
        }
    },
    {
        path: '/ano_account/success',
        name: 'ano_account_success',
        meta: {
            title: 'Félicitations !'
        },
        component: function (){
            if(isMobile){
                return import('@/mobileViews/Anonymous/AnonymousSuccess');
            }
            else{
                //return import('@/desktopViews/Checkout');
            }
        }
    },
    {
        path: '/:merchantSlug',
        name: 'merchant',
        component: function (){
            if(isMobile){
                return import('@/mobileViews/Merchant');
            }
            else{
                return import('@/desktopViews/Merchant');
            }
        }
    }
];